import React                                   from 'react';
import Img                                     from 'gatsby-image';
import { graphql, useStaticQuery }             from 'gatsby';
import { Center, Box, useMediaQuery, Heading } from '@chakra-ui/react';

import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';
import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Mounted          from '@interness/web-core/src/components/structure/Mounted/Mounted';

import RolexLayout   from '../../../components/Layout';
import Tracking      from '../../../components/Tracking';
import TwoColumn     from '../../../components/TwoColumn';
import Checkboard    from '../../../components/Checkboard';
import KeepExploring from '../../../components/KeepExploring';

const RolexWartungsverfahrenPage = () => {
  const data = useStaticQuery(query);
  const [portrait] = useMediaQuery('(max-width: 768px)');

  const media = {
    cover: {
      portrait: data.coverPortrait.childImageSharp.fluid,
      landscape: data.coverLandscape.childImageSharp.fluid,
    },
    assessment: {
      portrait: data.assessmentPortrait.childImageSharp.fluid,
      landscape: data.assessmentLandscape.childImageSharp.fluid,
    },
    preparation: {
      portrait: data.preparationPortrait.childImageSharp.fluid,
      landscape: data.preparationLandscape.childImageSharp.fluid,
    },
    dismantling: {
      portrait: data.dismantlingPortrait.childImageSharp.fluid,
      landscape: data.dismantlingLandscape.childImageSharp.fluid,
    },
    cleaning: {
      portrait: data.cleaningPortrait.childImageSharp.fluid,
      landscape: data.cleaningLandscape.childImageSharp.fluid,
    },
    assemblyLube: {
      portrait: data.assemblyLubePortrait.childImageSharp.fluid,
      landscape: data.assemblyLubeLandscape.childImageSharp.fluid,
    },
    refinishing: {
      portrait: data.refinishingPortrait.childImageSharp.fluid,
      landscape: data.refinishingLandscape.childImageSharp.fluid,
    },
    assemblyCase: {
      portrait: data.assemblyCasePortrait.childImageSharp.fluid,
      landscape: data.assemblyCaseLandscape.childImageSharp.fluid,
    },
    casing: {
      portrait: data.casingPortrait.childImageSharp.fluid,
      landscape: data.casingLandscape.childImageSharp.fluid,
    },
    precisionTest: {
      portrait: data.precisionTestPortrait.childImageSharp.fluid,
      landscape: data.precisionTestLandscape.childImageSharp.fluid,
    },
    waterproofTest: {
      portrait: data.waterproofTestPortrait.childImageSharp.fluid,
      landscape: data.waterproofTestLandscape.childImageSharp.fluid,
    },
    finalControl: {
      portrait: data.finalControlPortrait.childImageSharp.fluid,
      landscape: data.finalControlLandscape.childImageSharp.fluid,
    },
    returning: {
      portrait: data.returningPortrait.childImageSharp.fluid,
      landscape: data.returningLandscape.childImageSharp.fluid,
    },
    firstClass: {
      portrait: data.firstClassPortrait.childImageSharp.fluid,
      landscape: data.firstClassLandscape.childImageSharp.fluid,
    },
    banner: {
      portrait: data.bannerPortrait.childImageSharp.fluid,
      landscape: data.bannerLandscape.childImageSharp.fluid,
    },
  }
  return (
    <RolexLayout>
      <Tracking pageType={'servicing procedure page'}/>
      <Mounted>
        <section>
          <Img width="100%" height="25vh"
               fluid={portrait ? media.cover.portrait : media.cover.landscape}/>
        </section>
        <Spacer/>
        <section>
          <Center width="90%" margin="auto">
            <Box maxW="650px" w="100%" textAlign="center">
              <Box textTransform="uppercase">
                <p><b>Wartung Ihrer Rolex</b></p>
                <Box sx={{
                  letterSpacing: '4.5px',
                  fontWeight: '300',
                  lineHeight: '1.2',
                }}>
                  <Heading as="h1" size="lg" sx={{ hyphens: 'auto' }}>DIE ARBEITSSCHRITTE EINES KOMPLETTSERVICE</Heading>
                </Box>
              </Box>
              <p>
                Sie können Ihre Rolex Armbanduhr bei jedem offiziellen Fachhändler oder jeder Filiale der Marke zur
                Wartung abgeben. Bei der Übergabe der Armbanduhr nimmt das zuständige Per-sonal Ihre Wünsche auf. Danach
                wird die Uhr an einen Uhrmacher weitergeleitet.
              </p>
            </Box>
          </Center>
        </section>
        <Spacer/>
        <section style={{ width: '90%', margin: 'auto' }}>
          <ResponsiveIFrame maxHeight={56.25}
                            src={`https://www.youtube-nocookie.com/embed/xZOhQcFS--w?rel=0&amp;controls=0`}
                            title={`Rolex Wartung`} type={'youtube'}/>
        </section>
        <Spacer/>
        <section>
          <Checkboard title="DIAGNOSE DER ARMBANDUHR" imageSet={media.assessment}>
            Ihre Armbanduhr wird sorgfältig von einem Uhrmacher untersucht, der dann die durchzuführenden Maßnahmen
            abschätzt und einen Kostenvoranschlag erstellt. Nach Annahme des Kostenvoranschlags kann der Wartungsservice
            beginnen.
          </Checkboard>
          <Checkboard title="VORBEREITUNG DER WARTUNGSARBEITEN" imageSet={media.preparation} inverted>
            Das noch mit Zifferblatt und Zeigern versehene Uhrwerk wird aus dem Gehäuse herausgenommen, nachdem zuvor
            bereits das Armband entfernt wurde. Uhrwerk, Gehäuse und Armband, die während der Wartung getrennte Wege
            gehen, werden am Ende des Service wieder zusammengebaut.
          </Checkboard>
          <Checkboard title="ZERLEGEN DES UHRWERKS" imageSet={media.dismantling}>
            Das Uhrwerk wird vollständig zerlegt, und alle Komponenten werden mit größter Sorgfalt untersucht.
            Komponenten, die nicht mehr den Rolex Kriterien entsprechen, werden automatisch ersetzt.
          </Checkboard>
        </section>
        <section>
          <TwoColumn title="REINIGUNG DER WERKBESTANDTEILE" imageSet={media.cleaning} inverted>
            Die einzelnen Komponenten werden im Ultraschallbad gereinigt, um Verunreinigungen selbst in besonders schwer
            zugänglichen Bereichen zu beseitigen.
          </TwoColumn>
        </section>
        <section>
          <Box width="90%" maxW="1140px" textAlign="center" margin="auto">
            <Img fluid={portrait ? media.assemblyLube.portrait : media.assemblyLube.landscape}/>
          </Box>
          <Spacer/>
          <Center width="90%" margin="auto">
            <Box maxW="650px" w="100%" textAlign="center">
              <h3>
                MONTAGE DES UHRWERKS UND SCHMIERUNG
              </h3>
              <p>
                Die Komponenten werden getrocknet, und das Uhrwerk wird wieder komplett zusammen-gebaut und geschmiert.
                Der Uhrmacher reguliert dann das Uhrwerk ein erstes Mal nach den markeneigenen Kriterien.
              </p>
            </Box>
          </Center>
        </section>
        <section>
          <TwoColumn title="AUFFRISCHEN DES FINISHS VON GEHÄUSE UND ARMBAND" imageSet={media.refinishing}>
            Das Gehäuse wird vollständig zerlegt. Mittelteil, Lünette, Gehäuseboden und Armband wer-den je nach
            Originalfinish poliert oder satiniert. Diese Arbeiten erfordern Fingerfertigkeit und Feingefühl.
          </TwoColumn>
        </section>
        <section>
          <Checkboard title="ZUSAMMENBAU DES GEHÄUSES" imageSet={media.assemblyCase}>
            Nach erfolgter Auffrischung und Reinigung werden die Gehäuseteile wieder zusammengebaut, und die Dichtungen
            werden ersetzt. Die Wasserdichtheit des Gehäuses wird überprüft.
          </Checkboard>
          <Checkboard title="EINSCHALEN DES UHRWERKS" imageSet={media.casing} inverted>
            Der Uhrmacher setzt das zuvor wieder mit Zifferblatt und Zeiger versehene Uhrwerk in das Gehäuse ein,
            nachdem
            er all diese Elemente einer strengen technischen und ästhetischen Kontrolle unterzogen hat.
          </Checkboard>
        </section>
        <section>
          <TwoColumn title="GANGGENAUIGKEITSTEST" imageSet={media.precisionTest}>
            Nach dem Einschalen des Uhrwerks erfolgt eine erneute Überprüfung der chronometrischen Präzision. Der
            Uhrmacher nimmt nun die Feinanpassung vor, damit die Ganggenauigkeit den Anforderungen von Rolex entspricht.
            Nachdem der Uhrmacher den Gehäuseboden verschraubt hat, wird Ihre Armbanduhr mindestens vierundzwanzig
            Stunden getestet. Dabei wird das Uhrwerk einem markeneigenen Langzeit-Funktionstest unterzogen.
          </TwoColumn>
        </section>
        <section>
          <Box width="90%" maxW="1140px" textAlign="center" margin="auto">
            <Img fluid={portrait ? media.waterproofTest.portrait : media.waterproofTest.landscape}/>
          </Box>
          <Spacer height={30}/>
          <Center width="90%" margin="auto">
            <Box maxW="650px" w="100%" textAlign="center">
              <h3>
                WASSERDICHTHEITSPRÜFUNG
              </h3>
              <p>
                Die Wasserdichtheit Ihrer Armbanduhr wird mit einem Drucktest unter Wasser überprüft, bevor das Armband
                wieder am Gehäuse angebracht wird.
              </p>
            </Box>
          </Center>
        </section>
        <section>
          <TwoColumn title="ENDKONTROLLE" imageSet={media.finalControl}>
            Nun kann Ihre Armbanduhr einer Endkontrolle unterzogen werden. Dabei überprüft der Uhrmacher, ob die
            Funktionen vollends einsatzbereit sind und die ästhetischen Merkmale den Anforderungen entsprechen.
          </TwoColumn>
        </section>
        <section>
          <Checkboard title="RÜCKGABE DER ARMBANDUHR" imageSet={media.returning}>
            Nach Abschluss des Wartungsservice, der mit einer internationalen Servicegarantie von zwei Jahren auf Teile
            und Arbeitsleistung verbunden ist, wird Ihnen die Armbanduhr in einem Schutzetui überreicht.
          </Checkboard>
          <Checkboard title="ERSTKLASSIGER SERVICE" imageSet={media.firstClass} inverted>
            Rolex ist voll und ganz bestrebt, Ihnen einen erstklassigen Kundenservice zu bieten. Dank der hohen
            Ansprüche
            und des Know-hows unserer Uhrmacher besticht Ihre Rolex Armbanduhr auch nach Jahren noch durch
            ausgezeichnete
            Zuverlässigkeit und makellosen Glanz.
          </Checkboard>
        </section>
        <section>
          <Img width="100%" height="25vh"
               fluid={portrait ? media.banner.portrait : media.banner.landscape}/>
        </section>
        <Spacer/>
        <KeepExploring/>
        <Spacer/>
      </Mounted>
    </RolexLayout>
  )
};

export default RolexWartungsverfahrenPage;

const query = graphql`
    query {
        directusCompany {
            display_name
        }
        coverLandscape: file(name: {eq: "rolex-servicing-procedure-cover"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 2880) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        coverPortrait: file(name: {eq: "rolex-servicing-procedure-cover_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 750) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        assessmentLandscape: file(name: {eq: "rolex-servicing-procedure-assessment-of-the-watch"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        assessmentPortrait: file(name: {eq: "rolex-servicing-procedure-assessment-of-the-watch_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        preparationLandscape: file(name: {eq: "rolex-servicing-procedure-preparation-for-servicing"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        preparationPortrait: file(name: {eq: "rolex-servicing-procedure-preparation-for-servicing_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        dismantlingLandscape: file(name: {eq: "rolex-servicing-procedure-dismantling-of-the-movement"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        dismantlingPortrait: file(name: {eq: "rolex-servicing-procedure-dismantling-of-the-movement_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cleaningLandscape: file(name: {eq: "rolex-servicing-procedure-cleaning-the-movement"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cleaningPortrait: file(name: {eq: "rolex-servicing-procedure-cleaning-the-movement_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        assemblyLubeLandscape: file(name: {eq: "rolex-servicing-procedure-assembly-lubrication-of-the-movement"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        assemblyLubePortrait: file(name: {eq: "rolex-servicing-procedure-assembly-lubrication-of-the-movement_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        refinishingLandscape: file(name: {eq: "rolex-servicing-procedure-refinishing-of-the-case"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        refinishingPortrait: file(name: {eq: "rolex-servicing-procedure-refinishing-of-the-case_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        assemblyCaseLandscape: file(name: {eq: "rolex-servicing-procedure-assembly-of-the-case"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        assemblyCasePortrait: file(name: {eq: "rolex-servicing-procedure-assembly-of-the-case_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        casingLandscape: file(name: {eq: "rolex-servicing-procedure-casing-of-the-movement"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        casingPortrait: file(name: {eq: "rolex-servicing-procedure-casing-of-the-movement_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        precisionTestLandscape: file(name: {eq: "rolex-servicing-procedure-precision-test"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        precisionTestPortrait: file(name: {eq: "rolex-servicing-procedure-precision-test_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        waterproofTestLandscape: file(name: {eq: "rolex-servicing-procedure-waterproofness-test"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        waterproofTestPortrait: file(name: {eq: "rolex-servicing-procedure-waterproofness-test_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        finalControlLandscape: file(name: {eq: "rolex-servicing-procedure-final-control"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        finalControlPortrait: file(name: {eq: "rolex-servicing-procedure-final-control_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        returningLandscape: file(name: {eq: "rolex-servicing-procedure-returning-the-watch"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        returningPortrait: file(name: {eq: "rolex-servicing-procedure-returning-the-watch_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        firstClassLandscape: file(name: {eq: "rolex-servicing-procedure-first-class-service"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        firstClassPortrait: file(name: {eq: "rolex-servicing-procedure-first-class-service_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerLandscape: file(name: {eq: "rolex-servicing-procedure-worn-watch"} relativeDirectory: {eq: "landscape/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerPortrait: file(name: {eq: "rolex-servicing-procedure-worn-watch_portrait"} relativeDirectory: {eq: "portrait/service-procedure"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;